import React, { useEffect, useRef, useState } from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import { FixedSizeGrid as Grid, FixedSizeList as List } from "react-window";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useMediaQuery } from "@react-hook/media-query";
import Img from "gatsby-image";
import qs from "qs";
import useFetch from "react-fetch-hook";
import createTrigger from "react-use-trigger";
import useTrigger from "react-use-trigger/useTrigger";
import { ReactWindowScroller } from "react-window-scroller";
import SEO from "../components/seo";
import Skeleton from "../components/Skeleton";
import TrailTitle from "../components/TrailTitle";

const BgRow = styled(Row)`
  min-height: 75vh;
  background-image: url("${(props) => props.background}");
  background-repeat: no-repeat;
  background-position: top right;
`;

const fit = css`
  width: fit-content;
`;

const stickBottom = css`
  text-align: start;
  position: sticky;
  bottom: 4px;
  margin: 0 4px;
  z-index: 1;
`;

const stickTop = css`
  text-align: start;
  position: fixed;
  top: 4px;
  right: 0;
  margin: 0 4px;
  z-index: 1;
`;

const RoomsCard = ({ data, type }) => (
  <Card>
    <Card.Img
      variant='top'
      css={{
        objectFit: "cover",
        height: 175,
      }}
      src={`https://via.placeholder.com/400x200/${
        data.bed_kosong === 1 ? "c2912e" : "AFAFAF"
      }/FAFAFA?text=${data.ref_bed_rnp_nama}`}
    />
    <Card.Body>
      <Card.Title>Bed {data.ref_bed_rnp_nama}</Card.Title>
      <Row className='mb-3'>
        <Col xs={"6"}>
          <Badge variant={data.bed_kosong === 1 ? "primary" : "secondary"}>
            {data.bed_kosong === 1 ? "Tersedia" : "Sedang Ditempati"}
          </Badge>
        </Col>
      </Row>
    </Card.Body>
  </Card>
);

const useResponsiveBreakpoints = () => {
  const xs = useMediaQuery("only screen and (max-width: 600px)");
  const sm = useMediaQuery(
    "only screen and (min-width: 600px) and (max-width: 960px)"
  );
  const md = useMediaQuery(
    "only screen and (min-width: 600px) and (max-width: 1280px)"
  );
  const lg = useMediaQuery(
    "only screen and (min-width: 1280px) and (max-width: 1920px)"
  );
  const xl = useMediaQuery("only screen and (min-width: 1920px)");
  return { xs, sm, md, lg, xl };
};
const loadRooms = createTrigger();

const Roomses = ({ location }) => {
  const query = location.search ? qs.parse(location.search.substring(1)) : {};

  const { xs, sm, md, lg, xl } = useResponsiveBreakpoints();
  const [page, setPage] = useState(1);
  const [lastIndex, setLastIndex] = useState(0);
  const [noMore, setNoMore] = useState(false);

  const [Rooms, setRooms] = useState([]);
  const refetchRooms = useTrigger(loadRooms);
  const containerRef = useRef(null);

  const { isLoading, error, data } = useFetch(
    `https://rsgmiikbw.com/infobed.php`,
    {
      depends: [refetchRooms],
    }
  );

  useEffect(() => {
    if (data?.status === 200) {
      if (data.data.length > 0) {
        setRooms([...data.data]);
        setNoMore(true);
      } else {
        setNoMore(true);
      }
      // setRooms(data);
    }
  }, [data]);

  const responsiveWidth = containerRef.current
    ? containerRef.current.getBoundingClientRect()["width"] / 3
    : 300;

  if (error) {
    console.error(error);
  }

  return (
    <>
      <Col xs={12}>
        <div
          className='pt-3 pb-3'
          css={{ backgroundColor: "#F0F0F0" }}
          ref={containerRef}>
          {Rooms.length > 0 &&
            (xs || sm ? (
              <ReactWindowScroller>
                {({ ref, outerRef, style, onScroll }) => (
                  <List
                    ref={ref}
                    outerRef={outerRef}
                    style={style}
                    height={window.innerHeight}
                    itemCount={Rooms.length}
                    itemSize={300}
                    onScroll={onScroll}
                    // onItemsRendered={loadMoreItems}
                    overscanCount={4}>
                    {({ index: idx, style }) => {
                      const evt = Rooms[idx];

                      return evt === null ? (
                        <Skeleton
                          height='300px'
                          width='100%'
                          className='mb-2'
                          style={style}
                        />
                      ) : (
                        <div className={`mb-2`} style={style}>
                          <RoomsCard data={evt} type='Rooms' />
                        </div>
                      );
                    }}
                  </List>
                )}
              </ReactWindowScroller>
            ) : (
              <ReactWindowScroller isGrid>
                {({ ref, outerRef, style, onScroll }) => (
                  <Grid
                    ref={ref}
                    outerRef={outerRef}
                    style={style}
                    height={window.innerHeight}
                    width={window.innerWidth}
                    columnCount={3}
                    columnWidth={responsiveWidth}
                    rowCount={Math.ceil(Rooms.length / 3)}
                    rowHeight={300}
                    onScroll={onScroll}
                    // onItemsRendered={loadMoreItems}
                    overscanCount={3}>
                    {({ columnIndex, rowIndex, style }) => {
                      const evt =
                        Rooms[columnIndex + rowIndex + rowIndex] || null;

                      return evt === null ? (
                        <Skeleton
                          height='auto'
                          width='300px'
                          className='pl-1 pr-1'
                          style={style}
                        />
                      ) : (
                        <div className={`pl-1 pr-1`} style={style}>
                          <RoomsCard data={evt} type='Rooms' />
                        </div>
                      );
                    }}
                  </Grid>
                )}
              </ReactWindowScroller>
            ))}
          {!isLoading && Rooms.length === 0 && (
            <div className='d-flex justify-center align-items-center align-content-center p-5 bg-secondary'>
              Belum ada informasi terkait ketersediaan bed inap
            </div>
          )}
        </div>
        <div className='text-align-center p-2 text-secondary w-auto'>
          Informasi per {new Date().toLocaleString()}
        </div>
      </Col>
    </>
  );
};

const RoomsList = ({ background, location }) => {
  return (
    <BgRow className='pt-5' background={background}>
      <Col
        xs={"12"}
        md={"5"}
        className='d-flex flex-column align-items-center align-content-center'>
        <TrailTitle open className='pb-2 mb-5'>
          <div>Ketersediaan Bed Inap</div>
        </TrailTitle>
      </Col>
      <Col
        xs={"12"}
        // md={{ span: "10", offset: 1 }}
        //       xl={{ span: "8", offset: 2 }}
      >
        <h4 align='center' className='mb-3'>
          RSGM IIK BW memiliki fasilitas ruang rawat inap sejumlah 4 ruangan
          dengan total 10 tempat tidur.
        </h4>
        <Row>
          <Roomses location={location} />
        </Row>
      </Col>
    </BgRow>
  );
};

const sharebtns = css`
  & button {
    margin: 4px;
  }
`;

const carousel = css`
  & .carousel-indicators li {
    background-color: var(--primary);
  }
  & .carousel-control-next,
  .carousel-control-prev {
    color: var(--primary);
    font-size: 1.2rem;
  }
`;

const RoomsPage = ({ location, data }) => (
  <React.Fragment>
    <SEO title='Daftar Ruang Rawat Inap' />
    <Img fluid={data.cover.childImageSharp.fluid} />
    <RoomsList location={location} />
  </React.Fragment>
);

export const query = graphql`
  {
    bg: file(name: { eq: "bg-ring" }) {
      childImageSharp {
        fixed(toFormat: WEBP) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cover: file(name: { eq: "bg-common" }) {
      childImageSharp {
        fluid(maxWidth: 1920, toFormat: WEBP) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default RoomsPage;
